<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the
        <stemble-latex content="$\text{K}_{\text{sp}}$" />
        of
        <chemical-latex content="CdCO3" />
        if a saturated solution is prepared by dissolving
        <number-value :value="molSalt" unit="\text{mol}" />
        of the solute in
        <number-value :value="volume" unit="\text{L}" />
        of otherwise pure water.
      </p>

      <calculation-input
        v-model="inputs.Ksp"
        :prepend-text="'$\\text{K}_{\\text{sp}}:$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question310',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Ksp: null,
      },
    };
  },
  computed: {
    molSalt() {
      return this.taskState.getValueBySymbol('molSalt').content;
    },
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
  },
};
</script>
